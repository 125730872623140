@font-face {
  font-family: "Valia";
  src:url("Valia-Truelight.ttf.woff");
}

* {
  margin: 0;
  padding: 0;
}

*::-webkit-scrollbar{
  width: 10px;
  background-color: #f5f5f5;
}
*::-webkit-scrollbar-thumb {
  background-color: #333333;
  background-image: -webkit-linear-gradient(45deg,hsla(0,0%,100%,.2) 25%,transparent 0,transparent 50%,hsla(0,0%,100%,.2) 0,hsla(0,0%,100%,.2) 75%,transparent 0,transparent);
}
*::-webkit-scrollbar-track{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #f5f5f5;
}

body {
  background-color: #5a5a5a;
  min-height: 100vh;
  position: relative;
}

.App {
  text-align: center;
}

.navbar {
  display: block;
  width: 100%;
  height: 10vh;
  min-height: 5px;
  background: rgba(40,40,40,0.8);
  background: linear-gradient(180deg, rgba(40,40,40,0.8) 0%, rgba(40,40,40,0.45) 65%, rgba(40,40,40,0) 100%);
  position: fixed;
  z-index: 5;
  box-sizing: border-box;
  padding: 5px 0;
  font-family:Valia, Georgia, 'Times New Roman', Times, serif;
  transition: height 0.5s,background-color 0.5s;
}

.navbar.active {
  height:100vh;
}

.navbar-logo {
  height: 10vh;
  width:15%;
  float: left;
  text-align: center;
}

.navbar-logo img {
  height: 100%;
}

.navbar-spacer {
  height: 1px;
  width: 69%;
  float: left;
}
@media all and (max-width: 1200px) {
  .navbar-spacer {
    width: 23%;
  }
}
@media all and (max-width: 800px) {
  .navbar-spacer {
    width: 3%;
  }
}

.navbar-item {
  height: 4vh;
  width: 8%;
  float: left;
  text-align: center;
  display: block;
  border: none;
  background-color: transparent;
  font-size: 2vmin;
  position: relative;
  text-decoration: none;
  color: white;
  box-sizing: border-box;
}

.navbar-item a {
  color: white;
  text-decoration: none;
}

@media all and (max-width: 1200px) {
  .navbar-item {
    width: 15%;
  }
}
@media all and (max-width: 800px) {
  .navbar-item {
    width: 20%;
  }
}

.navbar-menu-button {
  width: 100%;
  height: 100%;
  background-color: transparent;
  border:none;
  color: #fff;
  font-size: 3vmin;
  text-align: left;
  position: relative;
  text-transform: capitalize;
  font-family:Valia, Georgia, 'Times New Roman', Times, serif;
  border-bottom:solid 1px #fff;
  box-sizing: border-box;
  padding: 0 10px;
}

.navbar-menu-button svg {
  height:1vmin;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.navbar-menu-button img {
  max-width: 100%;
  max-height: 100%;
  height:5vmin;
}

.navbar-menu-dropdown {
  height: auto;
  width: 100%;
  z-index: 100;
  box-sizing: border-box;
  position: absolute;
  padding: 10px;
  text-align: center;
  display: none;
  font-size: 2vmin;
  top:calc(100% + 5px);
}

.navbar-menu-dropdown.active {
  display: block;
}

.navbar-menu-item {
  height:2.5vh;
  width:100%;
  text-align: center;
  display: block;
  border: none;
  background-color: transparent;
  position: relative;
  text-decoration: none;
  color: white;
  box-sizing: border-box;
  font-size: 2vh;
  font-family:Valia, Georgia, 'Times New Roman', Times, serif;
  text-transform: capitalize;
}

.navbar-menu-dropdown a {
  color:white;
  text-decoration: none;
}

.footer {
  background: rgba(40,40,40,0.8);
  background: linear-gradient(0deg, rgba(40,40,40,0.8) 0%, rgba(40,40,40,0.45) 65%, rgba(40,40,40,0) 100%);
  position: absolute;
  height:3vh;
  width: 100%;
  bottom: 0;
}

.footer-copy-right {
  width: 100%;
  position: absolute;
  bottom: 0.2vh;
  font-size: 1vh;
  color: rgba(177, 177, 177, 0.5);
}

.footer-copy-right a {
  color: rgba(177, 177, 177, 0.5);
}

.page {
  width: 100%;
  padding-top: 10vh;
  padding-bottom: 10vh;
  box-sizing: border-box;
  color: white;
}

.page.pictures {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: 50vh;
  gap: 15px;
  padding: 10vh 15px;
  grid-auto-flow: dense;
}

.page.gallery {
  grid-template-rows: 50px;
}

.page.wrapper {
  display: table;
  width: 100%;
  height:100%;
  min-height: 97vh;
}

/* .page::after {
  content: "";
  clear: both;
  display: block;
} */

.picture {
  height: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: 5px 5px 5px rgb(40, 40, 40);
  cursor: pointer;
}

.picture.large-w {
  grid-column: span 2;
}

.picture.large-h {
  grid-row: span 2;
}

.picture img {
  height:100%;
  position: absolute;
  transform: translate(-50%,-50%);
  top: 50%;
  left: 50%;
}

.picture.large-w img {
  height: auto;
  width: 100%;
}

.pageBody {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}

.pageBody.galleryForm {
  color:white;
  font-size: 1.5vh;
  font-family:Valia, Georgia, 'Times New Roman', Times, serif;
}

.pageBody.galleryForm .form{
  margin: 0 auto;
  width: 500px;
}

.pageBody.galleryForm .form .form-input{
  height: 40px;
  width: 80%;
  margin: 0 auto;
  margin-top: 12px;
  display: grid;
  grid-template-columns: 80% 10%;
  column-gap: 5%;
}

.pageBody.galleryForm .form .form-input input {
  width: 100%;
  height: 100%;
  background-color: #3f3f3f;
  border: solid 2px #fff;
  box-sizing: border-box;
  border-radius: 10px;
  color: #fff;
  text-align: center;
  font-size: 20px;
  transition: border 0.5s;
  outline: solid 2px #fff;
}

.pageBody.galleryForm .form .form-input input:focus,.pageBody.galleryForm .form .form-input input:hover {
  border: solid 2px #3f3f3f;
}

.pageBody.galleryForm .form .form-input button {
  background: transparent;
  border: solid 2px #fff;
  /* border: none; */
  transition: border 0.5s;
  outline: solid 2px #fff;
  box-sizing: border-box;
  border-radius: 10px;
  color: #ffffff;
  font-size: 20px;
  background-color: #3f3f3f;
}

.pageBody.galleryForm .form .form-input button:hover {
  border: solid 2px #3f3f3f;
}

.bigPictures {
  background-color: rgba(63, 63, 63, 0.80);
  height: 100vh;
  width: 100vw;
  position: fixed;
  top:0;
  z-index: 10;

}

.bigPictures img {
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  max-width: 100%;
  max-height: 100%;
}

.page.pictures .galleryTitle {
  grid-column-end: span 3;
  color: #ffffff;
  font-size: 5vh;
  font-family:Valia, Georgia, 'Times New Roman', Times, serif;
  text-transform: capitalize;
  text-decoration: underline;
  text-decoration-thickness: 3px;
}



.contact.page {
  height:100vh;
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-template-rows: 1fr;
  box-sizing: border-box;
  padding-left: 10vw;
  padding-right: 10vw;
  background-color: rgb(220, 220, 220);
}

.contact-section {
  margin-bottom: 20px;
  align-self: center;
  color:rgba(63, 63, 63, 0.80);
  padding: 0 15px;
}

.contact-section h1,.contact-form h1 {
  font-size: 40px;
  font-weight: bold;
  font-family:Valia, Georgia, 'Times New Roman', Times, serif;
}

.contact-section h1 {
  text-decoration: underline;
  text-decoration-thickness: 2px;
}

.contact-list {
  list-style: none;
  padding: 0;
  padding-top: 20px;
}

.contact-list li {
  margin-bottom: 10px;
  display: grid;
  grid-template-columns: 1fr 3fr;
  align-items: center;
}

.contact-list li svg {
  justify-self: end;
  font-size: 22px;
}

.contact-list li a,.contact-list li span {
  color: rgba(63, 63, 63, 0.80);
  justify-self: start;
  box-sizing: border-box;
  padding-left: 30px;
}

.social-media-links {
  margin-top: 20px;
}

.social-media-links span {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
  text-decoration: underline;
}

.social-media-links ul {
  list-style: none;
  padding: 0;
  display: grid;
  justify-content: center;
  width: 70%;
  margin: 0 auto;
  grid-template-columns: 1fr 1fr 1fr;
}

.social-media-links ul li a {
  color:rgba(63, 63, 63, 0.80);
  font-size: 36px;
}

.contact-form {
  background-color: rgba(64, 64, 64, 0.8);
  height:100%;
  grid-row: span;
  color: #ffffff;
  box-sizing: border-box;
  padding: 40px 5vw;
  border-radius: 50px;
}

.contact-form h1 {
  margin-bottom: 20px;
}

.contact-form .form-group {
  height:auto;
  width: 100%;
  margin-bottom: 20px;
}

.contact-form .form-group label {
  display: block;
  width: 100%;
  font-family:Valia, Georgia, 'Times New Roman', Times, serif;
  font-size: 26px;
  font-weight: 600;
}

.contact-form .form-group input,.contact-form .form-group textarea {
  display: block;
  width: 100%;
  height:40px;
  background-color: #313131;
  border: #ffffff solid 3px;
  border-radius: 25px;
  padding: 10px;
  color: #ffffff;
  text-align: center;
  font-size: 20px;
}

.contact-form .form-group textarea {
  height:200px;
  resize: none;
  overflow: auto;
}

.contact-form button {
  background-color: #313131;
  color: #ffffff;
  border: none;
  padding: 10px 30px;
  font-size: 36px;
  font-family:Valia, Georgia, 'Times New Roman', Times, serif;
  font-weight: 600;
  font-style: italic;
  border-radius: 25px;
}


.page.shop {
  grid-template-rows: 50px;
}
.shop .shopTitle {
  grid-column: span 3;
  color: #ffffff;
  font-family:Valia, Georgia, 'Times New Roman', Times, serif;
  font-size: 36px;
}

.page.services {
  grid-template-rows: 50px;
}
.services .servicesTitle {
  grid-column: span 2;
  color: #ffffff;
  font-family:Valia, Georgia, 'Times New Roman', Times, serif;
  font-size: 36px;
}
.services{
  display: grid;
  grid-template-columns: 50% 50%;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  row-gap: 25px;
  column-gap: 25px;
  padding-left: 25px;
  padding-right: 25px;
}
.services .service {
  box-shadow: 10px 10px 5px rgb(40, 40, 40);
  transition: transform 0.25s ease-in-out ;
}
.services .service:hover {
  transform: scale(1.05);
}
.services .service.large {
  height:450px;
  overflow: hidden;
  grid-column: span 2;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 100%;
  justify-content: center;
  align-items: center;
}
.services .service.large >* {
  height: 100%;
  width: 100%;
}
.services .service.large .image {
  display: grid;
  justify-content: center;
  grid-template-rows: 100%;
  align-items: center;
}
.services .service.large .image img {
  height: 120%;
}
.services .service.large .texte {
  display: grid;
  justify-content: center;
  align-items: center;
  font-size: 19px;
  padding: 0 50px;
  box-sizing: border-box;
}
.services .service.large .texte h4 {
  font-size: 24px;
}
.services .service.large .texte p {
  margin: 14px 0;
}
.services .service.large .texte span {
  font-size: 22px;
}
.services .service.small {
  width: 100%;
  height:450px;
  display: grid;
  overflow: hidden;
  grid-template-rows: 45% 55%;
}
.services .service.small > * {
  width: 100%;
  height: 100%;
}
.services .service.small .image {
  display: grid;
  justify-content: center;
  grid-template-rows: 100%;
  align-items: center;
}
.services .service.small .image img {
  width: 100%;
}
.services .service.small .texte {
  display: grid;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  padding: 0 25px;
  box-sizing: border-box;
}
.services .service.small .texte h4 {
  font-size: 20px;
}
.services .service.small .texte p {
  margin: 10px 0;
}
.services .service.small .texte span {
  font-size: 16px;
}

.page.about{
  height: 100vh;
  width: 100%;
  display: grid;
  grid-template-columns: 80% 20%;
  grid-template-rows: 100%;
  overflow: hidden;
  padding-left: 50px;
  padding-right: 50px;
  box-sizing: border-box;
}
.about .text {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  overflow-y: auto;
  box-sizing: border-box;
  padding: 0 50px;
}
.about .photo {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
}
.about .photo img {
  position: absolute;
  height:75%;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
} 

.e404 {
  height:100vh;
  display: grid;
  justify-items: center;
  align-items: center;
  grid-template-columns: 100%;
  grid-template-rows: 45% 80px 100px 50px;
}
.e404 svg {
  height:60%;
  align-self: self-end;
}
.e404 h1 {
  font-size: 42px;
  align-self: self-end;
}
.e404 p {
  width: 1000px;
  text-align: center;
  font-size: 20px;
}
.e404 a {
  color:aquamarine;
  font-size: 18px;
}
@media screen and (width > 1400px) and (width <= 1920px) { 
  .services {
    grid-template-columns: repeat(4,1fr);
  }
  .services .servicesTitle {
    grid-column: span 4;
  }
  .services .service.large {
    grid-column: span 4;
  }
}
@media screen and (width <= 1400px) and (width > 1000px) { 
  .services {
    grid-template-columns: repeat(3,1fr);
  }
  .services .servicesTitle {
    grid-column: span 3;
  }
  .services .service.large {
    grid-column: span 3;
  }
}
@media screen and (width > 1920px) { 
  .services {
    grid-template-columns: repeat(6,1fr);
  }
  .services .servicesTitle {
  grid-column: span 6;
  }
  .services .service.large {
    grid-column: span 6;
  }
  .services .service.large .image img {
    height: auto;
    width: 100%;
  }
}